<template>
  <div>
    <WeeklyPlanComponent
      :editable="true"
      :classId="null"
      :academicId="null"
      :teacherId="teacherId"
      v-if="teacherId"
    ></WeeklyPlanComponent>
  </div>
</template>

<script>
import WeeklyPlanComponent from "../../components/Schedule/WeeklyPlanComponent";
import axios from "axios";
export default {
  name: "MySchedule",
  components: { WeeklyPlanComponent },
  data() {
    return {
      teacherId: "",
      classId: "",
      academicId: ""
    };
  },
  mounted() {
    axios
      .get(this.getApiUrl + "/teachers/getTeacherId", {
        headers: {
          Authorization: "Bearer " + localStorage.token
        }
      })
      .then(res => {
        this.teacherId = res.data.data.teacher_id;
      });
  }
};
</script>

<style></style>
